::ng-deep .flag-overlay {
  width: 20rem !important;

  .mat-pseudo-checkbox {
    display: none !important;
  }
}

.mapping-filter-dropdown {
  .mat-mdc-menu-content {
    display: flex !important;
    flex-direction: column;
    gap: 0.5rem;

    .dropdown-footer {
      padding:  0 0.75rem;
      padding-left: 2.5rem;
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    .batch-list {
      max-height: 400px;
    }
  }
}

.pledge-type-cell {
  .mat-mdc-select {
    max-width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.pledge-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  ::ng-deep .flag-overlay {
    width: calc(100% - 80px) !important;
  }
}
