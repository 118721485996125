// asterisk
.asterisk {
  color: var(--error);
}

// details form stepper
.stepper-content {
  &__header {
    padding: 12px 16px;
    background: var(--grey-50);
    border-bottom: 1px solid var(--grey-200);
    border: 1px solid var(--grey-200);
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }

  &__body {
    padding: 32px;
    border: 1px solid var(--grey-200);
    border-radius: 0 0 8px 8px;
    @include flex($direction: column, $gap: 20px);

    //   stepper radio buttons
    .radio-group {
      @include flex($direction: column, $gap: 6px);

      .mat-mdc-radio-group {
        @include flex($gap: 20px);

        .mat-mdc-radio-button {
          padding: 8px 12px;

          .mdc-radio {
            padding: 0;
            width: fit-content;
            height: fit-content;
            @include flex();
            .mat-mdc-radio-touch-target {
              @include flex($align-items: center);
            }
            margin-right: 12px;
            .mdc-radio__background,
            .mdc-radio__native-control {
              width: 24px;
              height: 24px;
            }
          }

          .mdc-label {
            @include font-open-sans(
              $size: 14px,
              $weight: 400,
              $lh: 20px,
              $color: var(--grey-shade-2)
            );
          }
        }
      }
    }

    //   stepper input fields
    .input__field {
      width: 20rem;

      .mat-mdc-text-field-wrapper {
        // padding: 12px 16px;

        .mat-mdc-form-field-infix {
          padding: 0;
        }
      }

      mat-label {
        margin-bottom: 4px;
      }
    }

    //   stepper date picker
    .calender {
      .mat-mdc-text-field-wrapper {
        padding: 3px 16px;
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: block !important;
      }
    }

    //   stepper text area
    .text-area {
      width: 100%;

      .mat-mdc-text-field-wrapper {
        padding: 12px 0 2px 16px;
        min-height: 6rem;
      }
    }

    .contact-field {
      &__container {
        position: relative;
        @include flex($align-items: flex-start);
        .country-dropdown {
          padding: 8px 0;
          border: 1px solid var(--grey-200);
          border-radius: 4px 0 0 4px;
          border-right: none;
          //   @include flex($gap: 16px);

          .mat-mdc-select {
            width: fit-content;

            .mat-mdc-select-trigger {
              @include flex($gap: 8px);
              padding-left: 12px;

              .mat-mdc-select-value {
                width: 21px;
                .mat-mdc-select-value-text {
                  height: 100%;
                  mat-select-trigger {
                    @include flex($align-items: center);
                  }
                }
              }

              .mat-mdc-select-arrow-wrapper {
                @include flex($justify-content: center);
                width: 24px !important;
              }
            }

            input {
              width: fit-content;
            }
          }

          &:hover {
            background: var(--grey-50);
          }
        }

        .default {
          .mat-mdc-select-value {
            width: 21px;
            height: 14px;
            background: url("http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg")
              no-repeat;
            background-size: 21px 14px;
          }
        }
        .default-flag {
          .mat-mdc-select-value {
            width: 21px;
            height: 14px;
            background-size: 21px 14px;
          }
        }

        .mat-mdc-form-field {
          flex: 1;
          .mat-mdc-text-field-wrapper {
            outline: none !important;
            border: 1px solid var(--grey-200);
            border-radius: 0 4px 4px 0;
            border-left: 0;
          }

          .mat-mdc-form-field-subscript-wrapper {
            position: absolute;
            left: 0;
            top: 100%;
          }
        }

        .mat-mdc-form-field-infix {
          @include flex();
        }
      }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled)
      .mdc-text-field__input {
      @include font-open-sans(
        $size: 14px !important,
        $weight: 600 !important,
        $lh: 20px !important,
        $color: var(--black-shade-1) !important
      );
    }
  }

  &__footer {
    padding: 36px 0;
    @include flex($justify-content: flex-end, $gap: 16px);
  }
}

.form-field__group {
  @include flex($gap: 20px);
}

.form-field__radio {
  width: 100%;

  .radio-group {
    width: 50%;
  }
}

.page__body {
  margin-top: 80px;
}

.app__header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--blue-primary) !important;
}

.dropdown-search__input {
  border: none;
  outline: none;
  padding: 16px;
  @include font-open-sans($size: 14px !important,
        $weight: 400 !important,
        $lh: 20px !important,
        $color: var(--grey-shade-2) !important);

  &::placeholder {
    @include font-open-sans($size: 14px !important,
        $weight: 400 !important,
        $lh: 20px !important,
        $color: var(--grey-neutral) !important);
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .stepper-content {
    &__header {
      padding: 8px 16px;

      .form-title {
        @include font-open-sans($size: 16px, $weight: 600, $lh: 24px);
      }
    }

    &__body {
      padding: 24px;

      .input__field {
        width: unset;
      }
    }

    &__footer {
      justify-content: center;
      padding: 32px 0;

      .mdc-button {
        width: 100%;
      }
    }
  }

  .form-field__group {
    flex-direction: column;
  }
}
