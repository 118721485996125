:root {
  --white: #ffffff;
  // h1 to h6 text color
  --heading1-6: #000000;

  //Black and grey shades
  --black: #000000;
  --black-shade-1: #282828;
  --grey-shade-1: #e4e4e4;
  --grey-shade-2: #5a5a5a;
  --grey-neutral: #a3a3a3;
  --grey-shade-3: #FCFCFC;
  --grey-shade-9: #F5F5F5;
  --grey-50: #f8f8f8;
  --grey-200: #e4e4e4;
  --grey-300: #D1D1D1;
  --grey-400: #B4B4B4;
  --gray-600: #52525B;
  --background-disbaled: #F0F0F0;
  --secondary-light: #818181;
  --background-light-2: #ECEFF2;

  //Green shades
  --green-shade-1: #a2dfa7;
  --feedback: #16af24;
  --success: #16af24;
  //Red shades
  --red-shade-1: #dc362e;
  --red-shade-2: #fef3f2;
  --error: #dc362e;

  //Blue shades
  --blue-primary: #004f9f;
  --blue-shade-2: #2c74d6;
  --background-hover: #054787;
  --background-pressed: #0A3B70;
  --blue-50: #F0F7FF;
  --blue-400: #34a0fc;
  --blue-100: #DFEDFF;

  //orange shdes
  --warning: #f79009;

  //pink shades
  --pink: #e741b8;

  /**
* Scroller Colors
*/

  --scroll-background: #fafafa;
  --scroll-border: #e8e8e8;
  --scroll-thumb-background: #d9d9d9;
  --scroll-thumb-hover: #7d7d7d;

  // overriding material variables
  --mat-sidenav-content-background-color: #ffffff;
}

//font variables
$font-family: "Open Sans";
