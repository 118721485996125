@import "./theme/variables";
@import "./theme/base/font";
@import "./theme/common-style/typography";
@import "./theme/common-style/checkbox";
@import "./theme/base/custom-material";
@import "./theme/common-style/button.scss";
@import "./theme/common-style/input.scss";
@import "./theme/common-style/paginator";
@import "./theme/common-style/radio-button";
@import "./theme/common-style/toggle-slider";
@import "./theme/common-style/common-styles";
@import "./theme/common-style/select";
@import "./theme/common-style/snackbar";
@import "./theme/common-style/tabs";
@import "./theme/common-style/chip";
@import "./theme/common-style/dropdown";
@import "./theme/common-style/data-table";

html,
body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @include font-open-sans();
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  display: none;
}

.mdc-checkbox__ripple {
  display: none !important;
}

.NgxEditor__Content {
  min-height: 423px !important;
}

.NgxEditor__MenuBar {
  gap: 12px !important;
  padding: 8px 12px !important;
  place-content: flex-end !important;
}

.NgxEditor__MenuItem.NgxEditor__MenuItem--Icon {
  width: 16px !important;
  height: 16px !important;
}

.NgxEditor__Content {
  padding: 12px 0 0 16px !important;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--white);
  border-width: 0px 0.5px;
}

.scroll::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 8px;
  background-color: #d9d9d9;

  &:hover {
    background-color: #7d7d7d;
  }
}

//Global scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #fafafa;
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: #e8e8e8;
}

::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-clip: padding-box;
  border-radius: 8px;
  background-color: #d9d9d9;

  &:hover {
    background-color: #7d7d7d;
  }
}

.asterix__after::after {
  content: "*";
  color: var(--error);
  margin-left: 2px;
}

.page__header {
  margin-top: 80px;
}

.cross-icon {
  &:hover {
    cursor: pointer;

    mat-icon {
      background: var(--grey-200);
    }
  }


}

::ng-deep .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
  border-color: var(--blue-primary) !important;
  background-color: var(--blue-primary) !important;
}

.mat-sort-header-content {
  text-align: start !important;
}

.NgxEditor__Placeholder:before {
  color: #a5a5a5 !important;
}

highcharts-chart {
  .highcharts-container {
    span {
      &.currency {
        position: absolute !important;
        transform: translate(-50%, -50%);
        left: 5.75rem !important;
        top: 4.25rem !important;
        font-family: "Open Sans" !important;
      }

      &.received-amount {
        position: absolute !important;
        transform: translate(-50%, -50%);
        left: 5.75rem !important;
        top: 5.625rem !important;
        font-size: 1.125rem !important;
        font-family: "Open Sans" !important;
      }

      &.total-pledge-amount {
        position: absolute !important;
        transform: translate(-50%, -50%);
        left: 5.75rem !important;
        top: 6.875rem !important;
        font-family: "Open Sans" !important;
      }

      &.pledge-amount {
        position: absolute !important;
        transform: translate(-50%, -50%);
        left: 5.75rem !important;
        top: 5.75rem !important;
        font-family: "Open Sans" !important;
      }
    }
  }
}

.container__chartCanvas {
  highcharts-chart {
    .highcharts-container {
      svg.highcharts-root {
        g.highcharts-legend {
          transform: translate(88px, 160px) !important;

          g {
            g {
              g.highcharts-legend-item.highcharts-pie-series.highcharts-color-0 {
                transform: translate(0, 3px) !important;
              }
              g.highcharts-legend-item.highcharts-pie-series.highcharts-color-1 {
                transform: translate(92px, 3px) !important;
              }
              g.highcharts-legend-item.highcharts-pie-series.highcharts-color-2 {
                transform: translate(220px, 3px) !important;
              }
            }
          }
        }
      }
    }
  }
}

.drawer-open {
  overflow: hidden;
}

.filter.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  max-height: 2.5rem;

  .mat-icon.filter-icon {
    width: 2.5rem;
    height: 2.5rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.heading-section {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          display: flex !important;
        }
      }
    }
  }
}

.multi-dropdown {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      .mat-mdc-paginator-page-size {
        mat-form-field.mat-mdc-form-field {
          .mat-mdc-text-field-wrapper {
            outline: none !important;

            .mat-mdc-form-field-flex {
              .mat-mdc-form-field-infix {
                padding: 4px 0 !important;

                .mat-mdc-select {
                  .mat-mdc-select-trigger {
                    .mat-mdc-select-value {
                      color: #5A5A5A;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-dropdown {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-input-element {
            white-space: nowrap;
            overflow: hidden !important;
            display: block;
            width: calc(100% - 32px);
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.link--text {
  color: #0A85ED
}

.stepper-body {
  .mdc-switch:disabled .mdc-switch__track::after {
    background: var(--blue-primary) !important;
  }
}

.calendar {
  width: 20px;
  height:20px;

  svg {
    fill: #7b7b7b !important;
  }
}

.editor {
  ngx-editor {
    .NgxEditor {
      .NgxEditor__Content {
        min-height: 6.625rem !important;
      }
    }
  }
}

.audit {
  .audit__header {
        mat-icon.icon {
          width: 20px;
          height: 17px;
        
          svg {
            width: 20px;
            height: 18.5px;
          }
        }
  }
}
