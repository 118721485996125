.mat-mdc-chip-listbox {
  .mdc-evolution-chip-set__chips {
    .mat-mdc-chip {
      margin: 0 0 0 8px;
      background-color: #818181 !important;
      padding: 4px 8px;
      height: 28px;
      border-radius: 20p;

      .mdc-evolution-chip__cell.mdc-evolution-chip__cell--primary {
        height: 20px;
        .mdc-evolution-chip__action {
          color: white !important;
          padding-left: 0;
          height: 20px;

          .mdc-evolution-chip__text-label {
            color: white !important;
            @include font-open-sans($size: 12px, $weight: 400, $lh: 20px);
          }
        }
      }

      .mdc-evolution-chip__cell.mdc-evolution-chip__cell--trailing {
        .mat-icon {
          padding-right: 0;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
