@import "../base/font";
@import "../base/mixins";

.mdc-snackbar__surface {
  padding: 0 !important;
  background: green !important;

  .mat-mdc-snack-bar-label {
    padding: 12px 16px !important;
    @include font-open-sans(
      $size: 14px,
      $weight: 600,
      $lh: 20px,
      $color: var(--white)
    );
  }
}

.snackbar-error {
  .mdc-snackbar__surface {
    background: var(--red-shade-2) !important;
    .mat-mdc-snack-bar-label {
      color: var(--error) !important;
      @include font-open-sans(
        $size: 14px,
        $weight: 400,
        $lh: 20px,
        $color: var(--error)
      );
    }
  }
}

.label {
  &__container {
    @include flex($align-items: center, $justify-content: space-between);

    .snackbar-close {
      cursor: pointer;
    }
  }

  &__content {
    @include flex($align-items: center, $gap: 12px);
  }
}
