.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--grey-neutral);
  --mdc-radio-disabled-unselected-icon-color: var(--grey-neutral);
  --mdc-radio-unselected-hover-icon-color: var(--grey-neutral);
  --mdc-radio-unselected-icon-color: var(--grey-neutral);
  --mdc-radio-unselected-pressed-icon-color: var(--blue-primary)(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--blue-primary);
  --mdc-radio-selected-hover-icon-color: var(--blue-primary);
  --mdc-radio-selected-icon-color: var(--blue-primary);
  --mdc-radio-selected-pressed-icon-color: var(--blue-primary);
  --mat-radio-ripple-color: none;
  --mat-radio-checked-ripple-color: none;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  background: var(--blue-primary);
}
