.mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
}
.mat-mdc-progress-bar {
    border-radius: 4px;
    background-color: var(--grey-shade-1);
}

.mdc-linear-progress__bar-inner {
    border-color: var(--blue-primary) !important;
}