@mixin font-open-sans($size: false, $color: false, $weight: false, $lh: false) {
	font-family: "Open Sans";

	@if $size {
		font-size: $size;
	}

	@if $color {
		color: $color;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $lh {
		line-height: $lh;
	}
}