.mat-mdc-paginator-container {
  gap: 1.5rem;
  padding: 6px 24px !important;

  .mat-mdc-paginator-page-size {
    height: 2.75rem;
    gap: 1.5rem;
    align-items: center !important;
    margin-right: 0 !important;

    .mat-mdc-paginator-page-size-label {
      margin: 0 !important;
      font-size: 14px;
      font-family: 'Open Sans';
      color: #5A5A5A;
      font-weight: 400;
    }

    .mat-mdc-paginator-page-size-select {
      width: 104px;
      margin: 0 !important;
    }
  }

  .mat-mdc-paginator-range-actions {
    gap: 1.5rem !important;

    .mat-mdc-paginator-range-label {
      margin: 0 !important;
      font-size: 14px;
      font-family: 'Open Sans';
      color: #5A5A5A;
      font-weight: 400;
    }

    button {
      padding: 0 !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }
}
