.mdc-button {
    padding: 0.75rem 1.5rem !important;

    .mat-mdc-button-touch-target {
        height: 100% !important;
    }

    .mdc-button__label {
        @include font-open-sans($size: 12px, $weight: 600, $lh: 18px)
    } 

    &.button--primary {
        background-color: #004F9F !important;
        color: white !important;
        &:hover {
            background-color: #054787;
        }
        &:enabled:active {
            background-color: #0A3B70;
        }
        &:disabled {
            background-color: #F0F0F0 !important;
            color: #B4B4B4 !important;
        }
    }

    &.button--secondary {
        background-color: #FFFFFF;
        outline: 1px solid #004F9F;
        color:#004F9F !important;
        font-family: Open Sans;
        @include font-open-sans($size:12px,$lh:150%,$weight:600);
        &:hover {
            background-color: #FFFFFF;
            outline: 1px solid #054787;
            color:#054787 !important;
        }
        &:enabled:active {
            background-color: #FFFFFF;
            outline: 1px solid #0A3B70;
            color:#0A3B70 !important;
        }
        &:disabled {
            background-color: #FFFFFF;
            outline: 1px solid #E4E4E4;
            color: #B4B4B4 !important;
        }
       
        
    }

    &.button--tertiary {
        background-color: #FFFFFF;
        outline : none;
        color: #004F9F !important;
        @include font-open-sans($size:12px,$lh:150%,$weight:600);
        &:hover {
           color: #054787 !important;
        }
        &:enabled:active {
            color: #0A3B70 !important;
        }
        &:disabled {
            color: #B4B4B4 !important;
        }
    }

    &.button--cancel {
        background-color: var(--error) !important;
        outline : none;
        color: #FFFFFF !important;;
    }

    &.button--social {
        background-color: #FFFFFF;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        @include font-open-sans($size: 14px, $weight: 600, $lh: 20px);

        &:hover {
            background-color:#F8F8F8;
        }

        &:disabled {
            background-color:#FFFFFF;
            color:#E4E4E4;
            .mat-icon {
                svg {
                    path {
                        stroke:#E4E4E4;
                        fill:#E4E4E4;
                    }
                }
            }
        }

    }
    &.button--link {
        .mdc-button__label {
            color: #004F9F;
        }
    }
};

.search-and-cta {
    .btn.mdc-button {
      .mdc-button__label {
        font-size: 0.875rem !important;
        letter-spacing: 0.1px;
        height: 21px;
        @include font-open-sans($size: 14px, $weight: 600, $lh: 18px)
      }
    }
  }