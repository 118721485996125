// toggle-slider-styles
.mdc-switch {
  width: 48px !important;

  .mdc-switch__track {
    border-radius: 16px !important;
    height: 24px !important;
  }

  .mdc-switch__handle-track {
    width: 24px !important;
    height: 24px !important;

    .mdc-switch__handle {
      position: relative;
      left: 4px;
      width: 16px !important;
      height: 16px !important;

      .mdc-switch__ripple {
        display: none;
      }

      .mdc-switch__icons {
        svg {
          display: none;
        }
      }

      &::after {
        background: var(--white) !important;
      }
    }
  }
}

.mdc-switch {
  &:enabled {
    .mdc-switch__track {
      &::before {
        background: var(--grey-200) !important;
      }

      &::after {
        background: var(--blue-primary) !important;
      }
    }
  }

  &:hover {
    .mdc-switch__track {
      &::before {
        background: var(--grey-300) !important;
      }
      &::after {
        background: var(--background-hover) !important;
      }
    }
  }

  &:active {
    .mdc-switch__track {
      &::before {
        background: var(--grey-400) !important;
      }
      &::after {
        background: var(--background-pressed) !important;
      }
    }
  }
  &:disabled {
    .mdc-switch__track {
      &::before {
        background: var(--secondary-light) !important;
      }
      &::after {
        background: var(--black) !important;
      }
    }
  }
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: var(--white) !important;
  //   border: 2px solid #e4eaf2;
}
