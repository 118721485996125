.input__field {
  display: flex;
  flex-direction: column;
}

.mat-label {
  @include font-open-sans($size: 14px,
    $weight: 400,
    $lh: 20px,
    $color: var(--grey-shade-2));

  &--disabled {
    @include font-open-sans($size: 14px,
      $weight: 400,
      $lh: 20px,
      $color: var(--grey-400));
  }
}

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    outline: 1px solid var(--grey-200) !important;
    background-color: #ffffff !important;
    border-radius: 4px;
    padding: 0px;

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: none !important;
    }

    .mat-mdc-form-field-flex {
      align-items: center;
    }
  }

  &.hide-hint-block {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 0 !important;
    padding: 10px !important;
  }

  &.mat-focused {
    &.mdc-text-field--focused {
      background-color: #ffffff !important;
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    .mdc-text-field__input {
      caret-color: black !important;
    }

    ::placeholder {
      @include font-open-sans($size: 14px !important,
        $weight: 400 !important,
        $lh: 20px !important,
        $color: var(--grey-neutral) !important);
    }
  }

  .mdc-text-field--invalid {
    outline: 1px solid red !important;
  }

  &--valid {
    .mat-mdc-text-field-wrapper {
      outline: 1px solid var(--feedback) !important;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    margin-top: 4px;

    .mat-mdc-form-field-error {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      @include font-open-sans($size: 12px,
        $color: var(--error),
        $weight: 400,
        $lh: 18px);
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper {
      &.mdc-text-field--disabled {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            .mat-icon {
              svg {
                path {
                  stroke: #e4e4e4 !important;
                }
              }
            }

            .mat-input-element,
            ::placeholder {
              @include font-open-sans($size: 14px !important,
                $weight: 400 !important,
                $lh: 20px !important,
                $color: var(--grey-neutral) !important);
            }
          }
        }
      }
    }
  }

  &.search {
    &.mat-focused {
      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--focused {
          outline: 1px solid #004f9f !important;
        }

        .mdc-line-ripple.mdc-line-ripple--active {
          background-color: red !important;
        }
      }
    }

    .mat-mdc-form-field-flex {
      height: 42px;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0px !important;
    }

    .mat-mdc-form-field-infix {
      padding: 8px !important;
      width: 24em;
      // width:30vw;
      height: 100%;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.calender {
  height: 100%;

  .mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }

  .mat-mdc-button-touch-target {
    height: 42px !important;
  }


  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  .mat-mdc-form-field-flex {
    align-items: center !important;
    justify-content: center !important;
    height: 42px;

    .mat-mdc-form-field-icon-suffix {
      align-self: center;
      height: 100% !important;

      .mat-datepicker-toggle {
        display: block;
        height: 100% !important;

        .mdc-icon-button {
          height: 100%;
          padding: 10px !important;
        }
      }
    }
  }

  .mat-date-range-input {
    .mat-date-range-input-container {
      justify-content: center;
      padding: 9px 0px 9px 8px;

      .mat-date-range-input-wrapper {

        ::placeholder {
          @include font-open-sans($size: 14px, $weight: 400, $lh: 20px);
          width: fit-content;
        }

        .mat-start-date {
          ::placeholder {}
        }

      }

      .mat-date-range-input-separator {
        // display: none !important;
        margin-left: 0px !important;
        margin-right: 6px !important;
        color: var(--grey-neutral) !important;
      }
    }
  }

}

.mat-mdc-paginator-container {
  .mat-mdc-paginator-page-size {
    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 4px 10px !important;
      }
    }
  }
}

.mat-date-range-input {
  .mat-date-range-input-container {
    justify-content: center;
    // padding: 9px 16px;

    .mat-date-range-input-wrapper {
      ::placeholder {
        @include font-open-sans($size: 14px, $weight: 400, $lh: 20px);
        width: fit-content;
      }

      .mat-start-date {
        ::placeholder {}
      }
    }

    .mat-date-range-input-separator {
      // display: none !important;
      margin-left: 0px !important;
      margin-right: 6px !important;
      color: #818181 !important;
    }
  }
}

.form-fields {
  width: 20rem;
  @include flex($direction: column, $gap: 4px);

  .mat-mdc-text-field-wrapper {
    padding: 12px 16px;

    .mat-mdc-form-field-infix {
      padding: 0 !important;
    }

    ::placeholder {
      @include font-open-sans($size: 14px !important,
        $weight: 400 !important,
        $lh: 20px !important,
        $color: var(--grey-neutral) !important);
    }
  }

  mat-label {
    margin-bottom: 4px;
  }
}

.mat-mdc-select-disabled {
  .mat-mdc-select-placeholder {
    @include font-open-sans($size: 14px !important,
      $weight: 400 !important,
      $lh: 20px !important,
      $color: var(--grey-400) !important);
  }
}

.mat-mdc-select-placeholder {
  @include font-open-sans($size: 14px !important,
    $weight: 400 !important,
    $lh: 20px !important,
    $color: var(--grey-neutral) !important);
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
  @include font-open-sans($size: 12px,
        $color: var(--secondary-light),
        $weight: 400,
        $lh: 18px);
}

.mat-mdc-select,
mat-option {
    @include font-open-sans($size: 14px !important,
        $weight: 400 !important,
        $lh: 20px !important)
}

.multi-select-dropdown-container {
  .mat-mdc-form-field .mat-mdc-form-field-infix {
    min-height: 0 !important;
    padding: 0 10px !important;
  }
}

.mat-mdc-form-field.multi-select-dropdown-container.has-value {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding: 4px 10px !important;
      }
    }
  }
}

.search-field {
  .search-icon {
    padding: 8px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding: 8px !important;
    padding-left: 0 !important;
  }
}

mat-select.multiselect-dropdown {
  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      .mat-mdc-select-placeholder {
        font-style: normal !important;
      }
    }
  }
}

.date-range-wrapper {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      height: 2.781rem !important;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-date-range-input {
            .mat-date-range-input-container {
              padding: 0 !important;

              .mat-date-range-input-separator {
                margin-left: 0px !important;
                margin-right: 2px !important;
                color: var(--secondary-light) !important;
              }
            }
          }
        }
        .mat-mdc-form-field-icon-suffix {
          .mat-datepicker-toggle {
            .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
              width: 45px;
              height: 45px;
            }
          }
        }
      }
    }
  }
}

.pledge-details-form {
  .pledge-details-option-section {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}