@import "../base/font";

.mat-mdc-select {
    .mat-mdc-select-trigger {
        background-image: url("../../assets/icons/grey-caret-down.svg"); 
        background-position: right center;
        background-repeat: no-repeat;

        .mat-mdc-select-value {
            @include font-open-sans($size: 14px, $weight: 600, $lh: 20px);
            $color: var(--grey-shade-2);
        }

        

        .mat-mdc-select-arrow-wrapper {
            .mat-mdc-select-arrow {
                svg {
                    display: none;
                } 
            }
        }
    }

    &.shortlisted {
        .mat-mdc-select-trigger {
            background-image: url("../../assets/icons/orange-caret-down.svg"); 
            background-position: right center;
            background-repeat: no-repeat;

            .mat-mdc-select-value-text {
                color: #F79009 !important;
            }
        }
    }
    &.hired {
        .mat-mdc-select-trigger {
            background-image: url("../../assets/icons/green-caret-down.svg"); 
            background-position: right center;
            background-repeat: no-repeat;
            .mat-mdc-select-value-text {
                color: #16AF24 !important;
            }
        }
    }
    &.notShortlisted {
        .mat-mdc-select-trigger {
            background-image: url("../../assets/icons/red-caret-down.svg"); 
            background-position: right center;
            background-repeat: no-repeat;
            .mat-mdc-select-value-text {
                color: #DC362E !important;
            }
        }
    }
    &.processing {
        .mat-mdc-select-trigger {
            background-image: url("../../assets/icons/caret-down.svg"); 
            background-position: right center;
            background-repeat: no-repeat;
            .mat-mdc-select-value-text {
                color: #004F9F !important;
            }
        }
    }
}

.mat-mdc-option {
    .mdc-list-item__primary-text {
        @include font-open-sans($size: 14px, $weight: 400, $lh: 20px);
        
    }
    &.processing {
        .mdc-list-item__primary-text {
            color:#004F9F !important;
        }
        
    }
    &.notShortlisted {
        .mdc-list-item__primary-text {
            color:#DC362E !important;
        }
    }
    &.shortlisted {
        .mdc-list-item__primary-text {
            color:#F79009 !important;
        }
    }
    &.hired {
        .mdc-list-item__primary-text {
            color:#16AF24 !important;
        }
    }

    &.mdc-list-item--selected {
        &.notShortlisted {
            .mdc-list-item__primary-text {
                color:#DC362E !important;
            }
        }
        &.shortlisted {
            .mdc-list-item__primary-text {
                color: #F79009 !important;
            }
        }
        &.hired {
            .mdc-list-item__primary-text {
                color:#16AF24 !important;
            }
        }
        &.processing {
            .mdc-list-item__primary-text {
                color:#004F9F !important;
            }
        }
    }

    .mat-pseudo-checkbox-minimal {
        display: none;
    }
}

.mat-mdc-select-panel {
    padding: 0px !important;
    margin-top:4px;
}

.dropdown {
    &.mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
            outline: none !important;
            background-color: white !important;

            
        }
        .mat-mdc-form-field-infix {
            padding: 12px 16px !important;
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    &.hired {
        .mat-mdc-text-field-wrapper {
            background-color: #F0FDF0 !important;
        }
    }
    &.shortlisted {
        .mat-mdc-text-field-wrapper {
            background-color: #FFFAEB !important;
        }
    }
    &.notShortlisted {
        .mat-mdc-text-field-wrapper {
            background-color: #FEF3F2 !important;
        }
    }

    &.processing {
        .mat-mdc-text-field-wrapper {
            background-color: #F0F7FF !important;
        }
    }


}
