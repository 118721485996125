@import "../base/font";

::ng-deep
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--blue-primary) !important;
  background-color: var(--blue-primary) !important;
}

.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-label {
      @include font-open-sans($size: 14px, $weight: 400, $lh: 20px, $color: var(--grey-shade-2))
    }
  }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: var(--blue-primary) !important;
  background-color: var(--blue-primary) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
  background-color: var(--blue-100) !important;
}