@import "../../theme/base/font";

.mat-mdc-tab-group {
  ::ng-deep .mat-mdc-tab-label-container {
    padding: 0 56px;
    .mdc-tab {
      padding: 0 16px;

      .mdc-tab__text-label {
        @include font-open-sans($size: 16px,
          $weight: 700,
          $lh: 24px,
          $color: var(--blue-primary));
      }
    }
  }

  .mat-mdc-tab-header {
    // margin-bottom: 1rem;
    width: fit-content;

    .mat-mdc-tab-list {
      .mat-mdc-tab {
        &.mdc-tab--active {
          .mdc-tab__content {
            .mdc-tab__text-label {
              font-weight: 600;
              color: var(--blue-primary);
            }
          }

          .mdc-tab-indicator {
            &__content--underline {
              border-color: var(--blue-primary);
            }
          }
        }
      }

      .mat-mdc-tab-labels {
        border-bottom: 1px solid var(--background-disbaled);
        width: fit-content;
      }
    }
  }
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .mat-mdc-tab-group {
    ::ng-deep .mat-mdc-tab-label-container {
      padding: 0 16px;
    }
  }
}