@import "../variables";
@import "../base/mixins";
@import "../base/font";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading1-6);
  font-weight: 700;
  font-family: $font-family;
  margin: 0;
}

h1 {
  @include font-open-sans($size: 36px, $lh: 44px);
}

h2 {
  @include font-open-sans($size: 32px, $lh: 38.4px);
}

h3 {
  @include font-open-sans($size: 28px, $lh: 33.6px);
}

h4 {
  @include font-open-sans($size: 24px,
    $color: var(--grey-shade-2),
    $weight: 700,
    $lh: 32px);
}

h5 {
  @include font-open-sans($size: 24px, $lh: 28px);
}

h6 {
  @include font-open-sans($size: 20px, $lh: 24px);
}

.h5-semibold {
  @include font-open-sans($size: 20px, $weight: 600, $lh: 24px);
}

.text-xlg-light {
  @include font-open-sans($size: 20px, $weight: 400, $lh: 30px);
}

.text-lg-medium {
  @include font-open-sans($size: 18px, $weight: 600, $lh: 28px);
}

.text-lg-bold {
  @include font-open-sans($size: 18px, $weight: 700, $lh: 28px);
}

.text-md-light {
  @include font-open-sans($size: 16px,
    $weight: 400,
    $lh: 24px,
    $color: var(--secondary-light));
}

.text-md-medium {
  @include font-open-sans($size: 16px, $weight: 600, $lh: 24px);
}

.text-md-bold {
  @include font-open-sans($size: 16px, $weight: 700, $lh: 24px);
}

.text-sm-light {
  @include font-open-sans($size: 14px,
    $weight: 400,
    $lh: 20px,
    $color: var(--grey-shade-2));
}

.text-sm-medium {
  @include font-open-sans($size: 14px, $weight: 600, $lh: 20px);
}

.text-sm-bold {
  @include font-open-sans($size: 14px, $weight: 700, $lh: 20px);
}

.text-xs-light {
  @include font-open-sans($size: 12px, $weight: 400, $lh: 18px);
}

.text-xs-medium {
  @include font-open-sans($size: 12px, $weight: 600, $lh: 18px);
}

.text-xs-bold {
  @include font-open-sans($size: 12px, $weight: 700, $lh: 18px);
}

.para-semibold {
  @include font-open-sans($size: 14px, $weight: 600, $lh: 20px);
}

.para-medium {
  @include font-open-sans($size: 16px,
    $color: var(--grey-shade-2),
    $weight: 400,
    $lh: 24px);
}

.para-regular {
  @include font-open-sans($size: 14px,
    $weight: 400,
    $lh: 20px);
}

.para-regular-12 {
  @include font-open-sans($size: 12px,
    $weight: 400,
    $lh: 18px);
}

.para-bold {
  @include font-open-sans($size: 18px, $weight: 700, $lh: 28px);
}

.para-bold-12 {
  @include font-open-sans($size: 12px, $weight: 700, $lh: 16px);
}

.para-bold-16 {
  @include font-open-sans($size: 16px, $weight: 700, $lh: 24px);
}